import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAccountMenu } from '../hooks/useAccountMenu';
import accountLogout from '../../../utils/logout';
import { CloseIcon } from './Icons';
import mediaQueries from '../../../utils/mediaQueries';
import { redirectTo } from '../../../utils/redirects';

const { accountPageUrl } = window.inlineGlobalConfig;

export const AccountNameMenu = ({ accountName }) => {
  const { isHovered, handleMouseEnter, handleMouseLeave, cancelHideDropdown } = useAccountMenu();

  const handleIconClick = event => {
    event.stopPropagation();
    handleMouseLeave();
  };

  // TODO refactor menu to be a separate component
  return (
    // eslint-disable-next-line
    <div
      className="dropdown-account__menu"
      onClick={event => {
        if (!event.target.closest('.icon-close')) {
          handleMouseEnter();
        }
      }}
      onTouchEnd={handleMouseEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        type="button"
        style={{ all: 'unset' }}
        onClick={() => {
          if (mediaQueries.is_large_up()) {
            redirectTo(accountPageUrl);
          }
        }}
      >
        <i className="fa-solid fa-user" />
      </button>
      {isHovered && (
        <div className="dropdown-content" onMouseEnter={cancelHideDropdown} onMouseLeave={handleMouseLeave}>
          <div className="dropdown-content__header">
            <p>
              <FormattedMessage id="account.nav.greeting" description="Hi" />, {accountName}
            </p>
            <CloseIcon onClick={handleIconClick} className="icon-close" />
          </div>
          <a className="dropdown-content__item" href={`${accountPageUrl}#info`}>
            <FormattedMessage id="account.nav.accountInfo" description="Account info" />
          </a>
          <a className="dropdown-content__item" href={`${accountPageUrl}#order-history`}>
            <FormattedMessage id="account.nav.orderHistory" description="Order history" />
          </a>
          <a className="dropdown-content__item" href={`${accountPageUrl}#address-book`}>
            <FormattedMessage id="account.nav.addressBook" description="Address book" />
          </a>
          <a className="dropdown-content__item" href={`${accountPageUrl}#payment-info`}>
            <FormattedMessage id="account.nav.paymentInfo" description="Payment info" />
          </a>
          <a className="dropdown-content__item" href={`${accountPageUrl}#settings`}>
            <FormattedMessage id="account.nav.settings" description="Settings" />
          </a>
          <div className="dropdown-content__sign-out">
            <button type="button" onClick={accountLogout}>
              <FormattedMessage id="header.navigation.account.logoutLink" description="Sign Out" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
